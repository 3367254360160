// src/components/RFQModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Toast } from 'react-bootstrap';

const RFQModal = ({ cart, product }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showToast, setShowToast] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Retrieve the cart from localStorage
const cart = JSON.parse(localStorage.getItem('cart')) || [];
const browsingHistory = JSON.parse(localStorage.getItem('browsingHistory')) || [];

// Ensure cart is an array before using map
if (!Array.isArray(cart)) {
  console.error('Cart is not an array');
  return;
}
    // Prepare the cart or product data for submission
    const dataToSend = product ? [product] : cart;
    const dataToSend1 = product ? [product] : browsingHistory;

    const payload = {
      ...formData,
      cart: dataToSend.map(item => ({ id: item.id, quantity: item.quantity })),
      browsingHistory: dataToSend1.map(item => ({ id: item.id })),
    };


//https://store-rfq.mahir1.workers.dev/api/submit-rfq
//http://localhost:3000/api/submit-rfq
    try {
      const response = await fetch('https://api.trustglobal.com.bd/api/submit-rfq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Something went wrong. Please try again.');
      }

      // Handle successful submission
      setShowToast(true);
      handleClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Request a Quote
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request a Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="text-danger">{error}</p>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                 placeholder="Enter your Name"
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                 placeholder="Enter your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              </Form.Group>
            <Form.Group controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              required
                />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                 placeholder="Enter your Address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
            

            
          </Form>
        </Modal.Body>
      </Modal>

       <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        autohide
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">RFQ Sent</strong>
        </Toast.Header>
        <Toast.Body>Thanks for the RFQ we will get back to you soon!</Toast.Body>
      </Toast>
    </>
  );
};

export default RFQModal;